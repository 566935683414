'use client'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'next/navigation'
import cn from 'classnames'
import Image from 'next/image'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import ReactPlayer from 'react-player'
import s from './style.module.css'
import ttsImg from './tts.png'

const TextGeneration = () => {
  const { t } = useTranslation()
  const params = useParams()
  const lang = params.lang as string
  const [isShowVideo, setIsShowVideo] = useState(false)

  useEffect(() => {
  }, [])

  return (
    <>
      <div className='text-center max-w-5xl m-auto mt-12'>
        <h1 className='text-7xl text-gray-900 break-words'>{t('common.index.title')}</h1>
        <h2 className={cn(s.h2bg, 'font-medium text-center bg-clip-text text-transparent text-2xl mt-6 mb-10')}>
          <Trans i18nKey="common.index.desc0">
          我们致力于将AI技术与企业需求深度融合<br/>让AI成为您的信息管家
          </Trans>
        </h2>
        <div className='text-[#696984] text-center text-lg leading-7'>
          <p className='mt-4 f3-mktg color-fg-muted mx-auto col-7-max'>
            <Trans i18nKey="common.index.desc1">
            以智能检索、ChatGPT、语音合成等强大功能，帮助企业提升运营效率、优化用户体验、驱动业务创新
            </Trans></p>
          <p>
            <Trans i18nKey="common.index.desc2">
            通过AI一体机，我们为企业提供一站式解决方案，助力企业实现数字化转型，引领智能时代
            </Trans>
          </p>
        </div>
      </div>
      <div className='flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center mt-8'>
        <Link href='mailto:1034603306@qq.com' className='px-8 py-3 flex justify-center items-center space-x-3 rounded-md border font-medium focus:outline-none focus:ring-2 focus:ring-violet-300 bg-violet-600 text-violet-50 border-violet-800 hover:bg-violet-500'>{t('common.index.contactUs')}</Link>
        <button className={s.btn2} onClick={() => { setIsShowVideo(true) }}>{t('common.index.watchVideo')}</button>
      </div>
      {isShowVideo
        ? <main className="absolute z-50 left-0 top-0 bottom-0 right-0 w-full h-full bg-black/75 ">
          <div className="absolute w-[640px] h-[360px] left-0 top-0 bottom-0 right-0 m-auto">
            <button onClick={() => { setIsShowVideo(false) }} className='absolute -right-28 w-16 h-16 -top-28 text-white'><XMarkIcon className='w-full h-full text-white' /></button>
            <ReactPlayer
              url='https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm'
              controls={true}
              playing={true}
            />
          </div>
        </main>
        : ''}
      <div className="mt-40">
        <div className={s.content}>
          <div className='flex-1 flex justify-center'>
            <Image
              src="/banner1.svg"
              width={500}
              height={500}
              alt="Picture of the author"
              loading = 'lazy'
            />
          </div>
          <div className='flex-1'>
            <h3 className='text-2xl font-semibold mb-4'>{t('common.index.search')}</h3>
            <div className='max-w-lg leading-relaxed text-gray-600'>
              <p>{t('common.index.searchDesc')}</p>
              <p>{t('common.index.searchDesc1')}</p>
              <ul className='mt-8 mb-4'>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.feat1')}</strong> {t('common.index.feat1Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.feat2')}</strong> {t('common.index.feat2Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.feat3')}</strong> {t('common.index.feat3Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.feat4')}</strong> {t('common.index.feat4Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.feat5')}</strong> {t('common.index.feat5Desc')}</p>
                </li>
              </ul>
            </div>
            <div className="max-w-lg flex">
              <Link href={`/${lang}/pages/search`} className={cn(s.btn1)}>Read More <span aria-hidden="true">→</span></Link>
            </div>
          </div>
        </div>

        <div className={cn(s.content)}>
          <div className='flex-1'>
            <div className='max-w-lg m-auto'>
              <h3 className='text-2xl font-semibold mb-4'>{t('common.index.audio')}</h3>
              <div className='max-w-lg leading-relaxed text-gray-600'>
                <p className='mb-2'>{t('common.index.audioDesc1')}</p>
                <p className='mb-2'>{t('common.index.audioDesc2')}</p>
                <p className='mb-2'>{t('common.index.audioDesc3')}</p>
              </div>
              <div className="max-w-lg flex">
                <Link href={''} className={cn(s.btn1)}>Read More <span aria-hidden="true">→</span></Link>
              </div>
            </div>
          </div>
          <div className='flex-1 flex justify-center'>
            <Image
              src={ttsImg}
              width={500}
              height={500}
              alt="Picture of the author"
              loading = 'lazy'
            />
          </div>
        </div>

        <div className={s.content}>
          <div className='flex-1 flex justify-center'>
            <Image
              src="/banner1.svg"
              width={500}
              height={500}
              alt="Picture of the author"
              loading = 'lazy'
            />
          </div>
          <div className='flex-1'>
            <h3 className='text-2xl font-semibold mb-4'>{t('common.index.AIMachine')}</h3>
            <div className='max-w-lg leading-relaxed text-gray-600'>
              <p>{t('common.index.AIMachineDesc')}</p>
              <p></p>
              <ul className='mt-8 mb-4'>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.AIMachine1')}</strong> {t('common.index.AIMachine1Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.AIMachine2')}</strong> {t('common.index.AIMachine2Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.AIMachine3')}</strong> {t('common.index.AIMachine3Desc')}</p>
                </li>
                <li className='flex space-x-3 mt-4'>
                  <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                  <p className='flex-1'><strong className='text-gray-800'>{t('common.index.AIMachine4')}</strong> {t('common.index.AIMachine4Desc')}</p>
                </li>
              </ul>
            </div>
            <div className="max-w-lg flex">
              <Link href={`/${lang}/pages/machine`} className={cn(s.btn1)}>Read More <span aria-hidden="true">→</span></Link>
            </div>
          </div>
        </div>

        <div className={s.content}>
          <div className='flex-1'>
            <div className='max-w-lg m-auto'>
              <h3 className='text-2xl font-semibold mb-4'>{t('common.index.code')}</h3>
              <div className='max-w-lg leading-relaxed text-gray-600'>
                <p>{t('common.index.codeDesc')}</p>
                <ul className='mt-8 mb-4'>
                  <li className='flex space-x-3 mt-4'>
                    <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                    <p className='flex-1'><strong className='text-gray-800'>{t('common.index.code1')}</strong> {t('common.index.codeDesc1')}</p>
                  </li>
                  <li className='flex space-x-3 mt-4'>
                    <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                    <p className='flex-1'><strong className='text-gray-800'>{t('common.index.code2')}</strong> {t('common.index.codeDesc2')}</p>
                  </li>
                  <li className='flex space-x-3 mt-4'>
                    <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                    <p className='flex-1'><strong className='text-gray-800'>{t('common.index.code3')}</strong> {t('common.index.codeDesc3')}</p>
                  </li>
                  <li className='flex space-x-3 mt-4'>
                    <CheckCircleIcon className='h-5 w-5 mt-1 text-violet-600'/>
                    <p className='flex-1'><strong className='text-gray-800'>{t('common.index.code4')}</strong> {t('common.index.codeDesc4')}</p>
                  </li>
                </ul>
              </div>
              <div className="max-w-lg flex">
                <Link href={`/${lang}/pages/codeGenerator`} className={cn(s.btn1)}>Read More <span aria-hidden="true">→</span></Link>
              </div>
            </div>
          </div>
          <div className='flex-1 flex justify-center'>
            <Image
              src="https://uploads-ssl.webflow.com/641002dd4cc67f18f6c3c158/6410cf1e5363d3780b47d3ed_train_eval.png"
              width={500}
              height={500}
              alt="Picture of the author"
              loading = 'lazy'
              className='rounded-xl shadow-2xl'
            />
          </div>
        </div>

        <div className={s.content}>
          <div className='flex-1 flex justify-center'>
            <Image
              src="/banner.svg"
              width={500}
              height={500}
              alt="Picture of the author"
              loading = 'lazy'
            />
          </div>
          <div className='flex-1'>
            <h3 className='text-2xl font-semibold mb-4'>Track, version, visualize everything</h3>
            <div className='max-w-lg leading-relaxed text-gray-600'>
              <p>Inputs and outputs of all steps are persisted as source of truth and visualizable in the dashboard.</p>
              <p>Dataframes, models, configuration dataclasses, images, metrics, plots and figures. You name it, Sematic tracks it and displays it for you in the UI.
              </p>
              <p>Rerun pipelines from the UI, from scratch or from any point. Cache results and implement fault tolerance for greater reliability.</p>
            </div>
            <div className="max-w-lg flex">
              <Link href={''} className={cn(s.btn1)}>Read More <span aria-hidden="true">→</span></Link>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
export const runtime = 'edge'
export default TextGeneration
